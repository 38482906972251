.app {
    margin: 0;
    display: flex;
    height: 100%;
    width: 100%;
}

.content_wrapper {
    position: relative;
    width: 100%;
    padding: 15px;
    height: min-content;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
}

.logo_wrapper {
    width: 200px;
    max-height: 50px;
}

.logo {
    height: 50px;
    padding: 4px 0;
    object-fit: contain;
}

@media screen and (max-width: 600px) {
    .content_wrapper {
        padding: 2px;
    }
    .content {
        max-width: 95%;
    }
}
