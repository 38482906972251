.labelWrapper {
    display: flex;
    justify-content: space-between;
}

.order {
    height: 22px;
    min-width: 22px;
    margin-left: 10px;
    align-self: center;
}

.icon {
    width: 18px;
    height: 18px;
}

.orderActive {
    border-radius: 5px;
    border: 2px solid var(--blue-base);
}

.orderActive:hover {
    background-color: var(--blue-tint);
}

.orderAsc::before {
    content: "";
    display: block;
    margin-left: 7px;
    height: 16px;
    width: 2px;
    background-color: var(--blue-hover);
    transform: rotate(45deg);
}

.orderDesc::after {
    content: "";
    display: block;
    margin-left: 7px;
    height: 16px;
    width: 2px;
    background-color: var(--blue-hover);
    transform: rotate(-45deg);
}