.wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
}

.container {
    display: flex;
    height: 36px;
    gap: 10px;
}

.label {
    width: 100%;
    margin-bottom: 3px;
}

.input {
    width: 100%;
    padding: 7px 8px;
    background: none;
    margin: 0;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 200;
}

.clear_button,
.clear_button_disabled {
    width: 36px;
    min-width: 36px;
    margin: 0;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.clear_button {
    background-color: var(--blue-base);
}

.clear_button_disabled {
    background-color: #dbdbdb;
}

.icon,
.icon_disabled {
    width: 100%;
    height: 100%;
    color: #fff;
    border-radius: 4px;
}

.icon {
    cursor: pointer;
}

.icon_disabled {
    cursor: not-allowed;
}
