.content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 15px;

    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    padding: 15px;

    min-width: 220px;
    max-width: 600px;

    box-shadow: 0 0 40px -10px #c9c9c9;
}

.title_wrapper {
    padding: 0 20px 0 20px;
}

.title {
    text-align: center;
}

.line {
    border-bottom: 1px solid var(--gray-base);
}

.profile {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.profile_info {
    text-align: center;
    padding: 0.5rem;
}

.profile_info_row {
    display: flex;
    justify-content: center;
    gap: 30px;
}

/* Table with info */

.table {
    text-align: center;
}

.table:hover {
    background-color: var(--blue-tint);
}

.th {
    width: 50%;
    padding: 0.5em;
}

.td {
    padding: 0.5em;
}
