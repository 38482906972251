.wrapper {
    width: min(490px, 100%);
    padding-inline: 10px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.input_label {
    cursor: default;
    display: block;
    font-weight: 500;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
}

.checkbox {
    width: 36px;
    height: 36px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;

}

.icon {
    width: 34px;
    height: 34px;
    color: #dbdbdb;
    cursor: pointer;
}

.icon:hover {
    color: var(--gray-dark)
}

.icon_selected {
    width: 34px;
    height: 34px;
    color: var(--blue-base);
    cursor: pointer;
}

.icon_selected:hover {
    color: var(--blue-hover)
}