.list_wrapper {
    width: 490px;
    padding: 0 10px 0 10px;
    margin: 15px 0 0 0;
    display: flex;
    flex-direction: column;
}

.list {
    width: 100%;
    height: 200px;
    background: none;
    border: 0;
    margin: 0;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    border-radius: 4px;
    overflow-y: hidden;
}

.list:hover {
    overflow-y: scroll;
}

.list::-webkit-scrollbar {
    width: 7px;
}

.list::-webkit-scrollbar-thumb {
    background: var(--blue-base);
    border-radius: 4px;
}

.list_item {
    text-align: left;
    font-weight: 200;
    background-color: #fff;
    padding: 7px 8px;
    cursor: pointer;
    border-bottom: 1px solid #dbdbdb;
    font-size: 1.4rem;
    min-height: 32px;
    user-select: none;
    word-wrap: break-word;
}

.map_wrapper {
    width: 490px;
    padding: 0 10px;
    margin-top: 15px;
}

.enterAddr {
    height: 0px;
    opacity: 0;
}

.enterActiveAddr {
    opacity: 1;
    height: 200px;
    transition:
        height 300ms,
        opacity 500ms,
        margin 300ms;
}

.exitAddr {
    height: 200px;
    opacity: 1;
}

.exitActiveAddr {
    height: 0px;
    opacity: 0;
    margin: 0;
    transition:
        height 300ms,
        padding 300ms,
        margin 300ms;
}
