.content {
    padding: 0 10px 10px 10px;
    width: 490px;
    margin: 0;
}

.button {
    cursor: pointer;
    background-color: var(--blue-base);
    color: white;
    border-radius: 4px;
    border: 0;
    width: 100%;
    padding: 10px 12px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}
.button:hover {
    background-color: var(--blue-hover);
}

#button_success {
    background-color: var(--green-base);
    transition: background-color 0.3s ease;
}
#button_success:hover {
    background-color: var(--green-hover);
}

#button_error {
    background-color: var(--red-base);
    transition: background-color 0.3s ease;
}
#button_error:hover {
    background-color: var(--red-hover);
}

.spinner {
    display: flex;
    justify-content: center;
    cursor: pointer;
    background-color: var(--blue-base);
    color: white;
    border-radius: 4px;
    border: 0;
    width: 100%;
    height: 40px;
    padding: 10px 12px;
}
