.single_input {
    width: min(490px, 100%);
    padding-inline: 10px;
    margin: 0;
}
.input_label {
    cursor: default;
    display: block;
    font-weight: 500;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 3px;
    user-select: none;
}

.input {
    width: 100%;
    padding: 7px 8px;
    background: none;
    margin: 0;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 200;
}

.input[type="text"] {
    background: #fff;
}

/* Firefox */
input[type="number"] {
    --moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.content {
    display: flex;
    flex-direction: column;
}

.input_with_button {
    min-width: 470px;
    height: 36px;
    display: flex;
    gap: 10px;
}

.button_small {
    width: 16px;
    height: 16px;
    background-color: var(--blue-base);
    margin: 0;
    border-radius: 4px;
}

.button {
    width: 36px;
    min-width: 36px;
    background-color: var(--blue-base);
    margin: 0;
    border-radius: 4px;

    transition: background-color 0.2s;
}

#button_disabled {
    background-color: #dbdbdb;
}

.icon {
    cursor: pointer;
    width: 100%;
    height: 100%;
    color: #fff;
    border-radius: 4px;
}

#icon_disabled {
    cursor: not-allowed;
}

/* ------------------- List with addresses ---------------- */
.list_wrapper {
    width: 490px;
    padding: 0 10px 0 10px;
    margin: 15px 0 0 0;
    display: flex;
    flex-direction: column;
}

.list {
    width: 100%;
    height: 160px;
    background: none;
    border: 0;
    margin: 0;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    border-radius: 4px;
    overflow-y: hidden;
}

.list:hover {
    overflow-y: scroll;
}

.list::-webkit-scrollbar {
    width: 7px;
}

.list::-webkit-scrollbar-thumb {
    background: var(--blue-base);
    border-radius: 4px;
}

.list_item {
    text-align: left;
    font-weight: 200;
    background-color: #fff;
    padding: 7px 8px;
    cursor: pointer;
    border-bottom: 1px solid #dbdbdb;
    font-size: 1.4rem;
    min-height: 32px;
    user-select: none;
    word-wrap: break-word;
}

.chexbox_list_item {
    text-align: left;
    font-weight: 200;
    background-color: #fff;
    padding: 7px 8px;
    cursor: pointer;
    border-bottom: 1px solid #dbdbdb;
    font-size: 1.4rem;
    min-height: 32px;
    user-select: none;
    word-wrap: break-word;
    display: flex;
    justify-content: space-between;
}

.list_item:hover {
    background-color: #ebf4fc;
}

.map_wrapper {
    width: 490px;
    padding: 0 10px;
    margin-top: 15px;
}

/*------------------ New address button --------------------- */

.new_addr_wrapper {
    width: 490px;

    padding: 0 10px 0 10px;
}

.new_addr_or {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    color: var(--gray-dark);
}

.new_addr {
    cursor: pointer;
    height: 36px;
    padding: 8px;
    border-radius: 4px;
    background-color: var(--blue-tint);
    border: 1px solid var(--gray-base);
    border-radius: 4px;
    color: var(--font-base);
    font-size: 1.5rem;
    text-align: center;
}

.new_addr:hover {
    background-color: var(--blue-tint-tint);
}

/*------------------ CssTransition --------------------- */
.enter {
    height: 0px;
    opacity: 0;
}

.enterActive {
    opacity: 1;
    height: 160px;
    margin-top: 15px;
    transition:
        height 300ms,
        opacity 300ms,
        margin 300ms;
}

.exit {
    height: 160px;
    opacity: 1;
}

.exitActive {
    height: 0px;
    opacity: 0;
    margin: 0;
    transition:
        height 300ms,
        padding 300ms,
        opacity 300ms,
        margin 300ms;
}
/*  */
.enterAddr {
    height: 0px;
    opacity: 0;
}

.enterActiveAddr {
    opacity: 1;
    height: 175px;
    transition:
        height 300ms,
        opacity 500ms,
        margin 300ms;
}

.exitAddr {
    height: 175px;
    opacity: 1;
}

.exitActiveAddr {
    height: 0px;
    opacity: 0;
    margin: 0;
    transition:
        height 300ms,
        padding 300ms,
        margin 300ms;
}
