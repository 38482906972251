.content_wrapper {
    height: fit-content;
    background-color: var(--blue-tint);
    padding: 20px 15px;
    font-weight: 550;
    border-bottom: 1px solid var(--gray-base);
}

.content {
    display: flex;
    justify-content: space-between;

    overflow: hidden;
}

.content_column {
    display: flex;
    gap: 15px;
    width: 75%;
    margin: auto;
    align-content: center;
    flex-direction: column;
}

.mapPng {
    width: 100%;
    height: 320px;
    object-fit: contain;
}

@media screen and (max-width: 700px) {
    .content_wrapper {
        padding: 3px;
    }

    .content_column {
        width: 100%;
    }
}

.comment_title {
    margin-inline: 10px;
    margin-bottom: 3px;
}

.comment_wrapper {
    background-color: #fff;
    margin-inline: 10px;
    padding: 10px;
    border: 1px solid var(--gray-base);
    border-radius: 4px;
}

.comment {
    font-weight: 300;
}
