.logo_wrapper {
    width: 200px;
    max-height: 50px;
}

.logo {
    height: 50px;
    padding: 4px 0;
    object-fit: contain;
}

.button_wrapper {
    display: flex;
    align-items: stretch;
    padding: 4px 0;
}

.button {
    width: 140px;
    cursor: pointer;
    border: 1px solid var(--blue-base);
    background-color: #fff;
    color: var(--blue-base);
    font-size: 1.6rem;
    text-decoration: none;
    white-space: nowrap;
    transition: background-color 0.25s;
}
.button:hover {
    background-color: var(--blue-tint);
}

#button_active {
    background-color: var(--blue-base);
    color: #fff;
}
