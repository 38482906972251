.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    height: 100%;
    font-size: 1.6rem;
    width: 75%;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}
@media screen and (max-width: 600px) {
    .content {
        width: 95%;
    }
}

.heading {
    margin: 0 0 15px 0;
    padding: 0 20px 0 20px;
}

.form_choice {
    margin: 0 0 15px 0;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px 0 20px;
    flex-wrap: wrap;
    max-width: 500px;
}

.button {
    cursor: pointer;
    padding: 10px 12px;
    border: 0;
    border-radius: 4px;
    background-color: var(--blue-base);
    color: white;
    text-decoration: none;
    white-space: nowrap;
    transition: background-color 0.3s;
}
.button:hover {
    background-color: var(--blue-hover);
}
#button_active {
    background-color: var(--blue-selected);
}

.form_wrapper {
    background-color: #fff;
    box-shadow: 0 0 40px -10px #c9c9c9;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 15px;
    width: min(560px, 100%);
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
}

.form_header {
    font-weight: 500;
    font-size: 2rem;
    padding: 0 10px 0 10px;
}
