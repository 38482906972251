.content_wrapper {
    overflow: clip;
    padding: 0 10px;
}

.content {
    display: flex;
    gap: 10px;
}

.input_with_label_left {
    flex: 0 0 calc(50% - 5px);
    position: relative;
}

.input_with_label_right {
    flex: 0 0 calc(50% - 5px);
}

.label {
    cursor: default;
    display: block;
    font-weight: 500;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 3px;
    user-select: none;
}

.input {
    width: 100%;
    padding: 7px 8px;
    background: none;
    margin: 0;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 200;
}

.input[type="text"] {
    background: #fff;
}

/* Firefox */
.input[type="number"] {
    --moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.list_wrapper {
    width: 470px;
    margin: 15px 0 0 0;
    display: flex;
    flex-direction: column;
}

.list {
    width: 100%;
    height: 160px;
    background: none;
    border: 0;
    margin: 0;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    border-radius: 4px;
    overflow-y: hidden;
}

.list_button {
    cursor: pointer;
    width: 100%;
    height: 36px;
    background: none;
    border: 0;
    margin: 0;
    border: 1px solid #dbdbdb;
    background-color: var(--blue-tint);
    border-radius: 4px;
    overflow-y: hidden;
    padding: 7px;
    text-align: center;
    margin: 15px 0 0 0;
}

.list_button:hover {
    background-color: var(--blue-tint-tint);
}

.list:hover {
    overflow-y: scroll;
}

.list::-webkit-scrollbar {
    width: 7px;
}

.list::-webkit-scrollbar-thumb {
    background: var(--blue-base);
    border-radius: 4px;
}

.list_item {
    text-align: left;
    font-weight: 200;
    background-color: #fff;
    color: var(--font-base);
    padding: 7px 8px;
    cursor: pointer;
    border-bottom: 1px solid #dbdbdb;
    font-size: 1.4rem;
    min-height: 32px;
    user-select: none;
    word-wrap: break-word;
}

/* CSS TRANSITION */

.enter {
    height: 36px;
    opacity: 0;
}

.enterActive {
    opacity: 1;
    height: 160px;
    margin-top: 15px;
    transition:
        height 300ms,
        opacity 300ms,
        margin 300ms;
}

.exit {
    height: 160px;
    opacity: 1;
}

.exitActive {
    height: 0px;
    opacity: 0;
    margin: 0;
    padding: 0;
    border: 0;
}

/* second css transistion */

.enter2 {
    height: 36px;
    opacity: 0;
}

.enterActive2 {
    opacity: 1;
    height: 36px;
    margin-top: 15px;
    transition:
        height 300ms,
        opacity 300ms,
        margin 300ms;
}

.exit2 {
    height: 36px;
    opacity: 1;
}

.exitActive2 {
    height: 0px;
    opacity: 0;
    margin: 0;
    padding: 0;
    border: 0;
}
