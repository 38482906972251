.content_wrapper {
    height: fit-content;
    background-color: var(--blue-tint);
    padding: 20px 15px;
    font-weight: 300;
    border-bottom: 1px solid var(--gray-base);
}
.content {
    background-color: #fff;
    border: 1px solid var(--gray-base);
    border-radius: 4px;
    overflow: hidden;
}
.header {
    width: 100%;
    padding: 15px 0;
    text-align: center;
    border-bottom: 1px solid var(--gray-base);
}
.item {
    padding: 20px 15px;
}

.table {
    max-width: 100%;
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.thead {
    border-bottom: 1px solid var(--gray-base);
}

.tbody {
    max-height: 350px;
    overflow-y: scroll;
}

.th {
    overflow: hidden;
    font-weight: 500;
    text-align: left;
    text-overflow: ellipsis;
    padding: 10px 15px;
}

.td {
    border-bottom: 1px solid var(--gray-base);
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding: 10px 15px;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    font-weight: 300;
}

.tr_td {
    height: 50px;
}

.tr_td:hover {
    background-color: #f8f8ff;
}

.button_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.button_group {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
