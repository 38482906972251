.tr,
.tr_header {
    user-select: none;
    padding: 10px;
}

.tr_header {
    background-color: var(--blue-tint);
    border: 1px solid var(--gray-base);
}

.tr {
    cursor: pointer;
    border-bottom: 1px solid var(--gray-base);
}

.tr:hover {
    background-color: var(--gray-tint-tint);
}

.td {
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding: 10px 15px;
    word-wrap: normal;
    word-break: keep-all;
    font-weight: 300;
}

.td_header {
    height: 10px;
    padding: 5px 15px;
}

.status_table {
    width: 6px;
    height: 50px;
}

/* Responsivenes */
@media screen and (max-width: 700px) {
    .tr {
        border-bottom: 3px solid var(--gray-base);
        display: block;
        padding: 3px;
        margin-bottom: 10px;
    }

    .td {
        border-bottom: 1px solid var(--gray-base);
        padding: 3px;
        height: 50px;
        display: block;
        font-size: 1.4rem;
        text-align: right;
    }
}
