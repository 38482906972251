.header {
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    height: 50px;
    width: 100%;
    font-family: Poppins, sans-serif;
    z-index: 3;
    user-select: none;
}

.header_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 15px;
    gap: 10px;
}

.items_wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .loggedInAs {
        display: none;
        padding: 10px 10px;
    }
}

.logout,
.button {
    padding: 10px 12px;
    cursor: pointer;
    border: 0;
    border-radius: 4px;
    color: white;
    font-size: 1.4rem;
    text-decoration: none;
    transition: background-color 0.25s;
    min-width: 90px;
}

.logout {
    background-color: #df4f5d;
}

.button {
    background-color: var(--blue-base);
}

.logout:hover {
    background-color: #f13447;
}

.lang_menu {
    position: relative;
}
