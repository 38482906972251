.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    overflow-y: auto;
    background-color: #30365799;
    z-index: 9999999999;
}

.buttons_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;

    background-color: #fff;
    border: 2px solid var(--blue-selected);
    border-radius: 4px;
    box-shadow: 0 0 40px -10px #c9c9c9;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title {
    text-align: center;
    padding: 20px 0 20px 0;
    background-color: var(--blue-tint);
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 20px;
}

.button {
    cursor: pointer;
    text-align: center;
    padding: 20px 0 20px 0;
    border-bottom: 1px solid var(--gray-base);
    display: flex;
    justify-content: center;
}

.button:hover {
    background-color: var(--blue-tint);
}

.icon {
    width: 30px;
    height: 30px;
}
