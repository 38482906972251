.single_input {
    width: min(490px, 100%);
    padding-inline: 10px;
    margin: 0;
}

.double_input_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 490px;
    padding-inline: 10px;
    gap: 10px;
}

.double_input {
    width: min(230px, 100%);
}

.input_label {
    cursor: default;
    display: block;
    font-weight: 500;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 3px;
    user-select: none;
}

.input {
    width: 100%;
    padding: 7px 8px;
    background: none;
    margin: 0;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 200;
}

.input[type="text"] {
    background: #fff;
}

/* Firefox */
input[type="number"] {
    --moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
