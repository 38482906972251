.pricings_list_wrapper {
    margin: 10px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pricings_header {
    padding: 10px 10px 10px 20px;
    font-size: 1.6rem;
    font-weight: 500;
    width: 100%;
    border-bottom: 1px solid #dbdbdb;
    text-align: center;
}

.pricings_items {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.pricings_item {
    padding: 15px 10px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--gray-base);

    transition: background-color 400ms;
}

.pricings_item:last-child {
    border-bottom: none;
}

.pricings_item_marked {
    padding: 15px 10px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--gray-base);

    background-color: #df4f5d;
    color: white;

    transition: background-color 400ms;
}

.pricings_item_marked:last-child {
    border-bottom: none;
}

.pricingsEnter {
    opacity: 0;
}

.pricingsEnterActive {
    opacity: 1;
    transition: opacity 300ms;
}
.pricingsExit {
    opacity: 1;
}

.pricingsExitActive {
    opacity: 0;
    transition: opacity 100ms ease-in;
}

.contract_pricings {
    overflow: hidden;
}

.choices {
    margin: 0 0 15px 0;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px 0 20px;
    flex-wrap: wrap;
    max-width: 500px;
}

.button {
    cursor: pointer;
    padding: 10px 12px;
    border: 0;
    border-radius: 4px;
    background-color: var(--blue-base);
    color: white;
    text-decoration: none;
    white-space: nowrap;
    transition: background-color 0.3s;
}
.button:hover {
    background-color: var(--blue-hover);
}
#button_active {
    background-color: var(--blue-selected);
}
