.nav {
    background-color: var(--nav-base);
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    color: white;

    position: fixed;
    top: 0;
    left: 0;

    z-index: 4;
    font-size: 1.6rem;
    height: 100%;
    z-index: 9999;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: opacity 1s;
}

.list {
    display: flex;
    flex-flow: column wrap;

    margin: auto;
    margin-top: 50px;
    padding: 0px;
    list-style-type: none;
}

.icon_wrapper {
    width: 30px;
    height: 30px;
}

.icon {
    width: 30px;
    height: 30px;
}

.navitem,
.navitem_active {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 0px 0 18px;
    gap: 10px;
    border-radius: 12px;

    white-space: nowrap;
    text-decoration: none;
    color: white;

    transition: background-color 0.3s ease-in;
}

.navitem:hover {
    background-color: var(--nav-hover);
}

.navitem_active {
    background-color: var(--blue-base);
}

.nav,
.navitem,
.navitem_active {
    transition: width 0.3s ease;
}

.p {
    transition: opacity 0.3s ease;
    overflow: hidden;
}

.count {
    position: absolute;
    left: 50px;
    top: -10px;
    color: #000;
    background-color: var(--blue-tint);
    padding: 4px;
    border: 1px solid var(--blue-base);
    border-radius: 4px;
}

/* ------------------ tooltip ----------------- */

.navitem_active .tooltiptext,
.navitem .tooltiptext {
    visibility: hidden;

    background-color: var(--nav-base);
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 6px;
    opacity: 0%;

    /* Position the tooltip */
    position: absolute;
    left: 40px;
    z-index: 9999;

    transition:
        opacity 0.4s,
        transform 0.3s;
}

.navitem_active:hover .tooltiptext,
.navitem:hover .tooltiptext {
    visibility: visible;
    opacity: 90%;
    transform: translateX(30px);
    z-index: 9999;
}
