.signin {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-top: 50px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 80px 10px 0 10px;
    width: fit-content;
    max-width: 450px;
}

.header {
    cursor: default;
    font-weight: 500;
    width: 100%;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 2rem;
}

.logo_wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
}

.logo {
    max-width: 100%;
    margin-bottom: min(50px, calc(5vw));
    object-fit: contain;
}

.form_field {
    width: 100%;
}

.form_label {
    cursor: default;
    display: block;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 3px;
}

.form_input_wrapper {
    height: 100%;
    width: 100%;
    align-items: center;
    background-color: #fff;

    transition: all 0.25s;
    display: flex;
}

.form_input {
    width: 100%;
    padding: 7px 8px;
    background: none;
    border: 0;
    margin: 0;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
}

.form_input::placeholder {
    color: #dbdbdb;
}

.form_input:focus {
    border: 1px solid #3699ff;
    border-radius: 4px;
}

.errmsg {
    text-align: center;
    color: #f13447;
}

.submit_button {
    cursor: pointer;
    background-color: #3699ff;
    color: white;
    border-radius: 4px;
    border: 0;
    width: 100%;
    height: 40px;
    padding: 10px 12px;
    text-decoration: none;
}

.submit_button:hover {
    background-color: #2070ff;
}

.spinner_button {
    display: flex;
    justify-content: center;
    cursor: pointer;
    background-color: #2070ff;
    color: white;
    border-radius: 4px;
    border: 0;
    width: 100%;
    height: 40px;
    padding: 10px 12px;
    text-decoration: none;
}
