:root {
    --font-base: #303657;

    --blue-base: #3699ff;
    --blue-hover: #6d85e7;
    --blue-tint: #ebf4fc;
    --blue-tint-hover: #dde5ed;
    --blue-selected: #002c7d;

    --nav-base: #1e1e2d;
    --nav-hover: #573956;

    --red-base: #df4f5d;
    --red-hover: #f13447;
    --red-tint: #feeaec;

    --green-base: #00b386;
    --green-hover: #009973;
    --green-tint: #d9fbf2;

    --gray-base: #dbdbdb;
    --gray-dark: #bbbbbb;
    --gray-darker: #aaaaaa;
    --gray-tint: #ededed;
    --gray-tint-tint: #f0f0f0;

    --oragne-base: #ffcc00;

    --status-new: #ffcc00;
    --status-pending: #cdcdcd;
    --status-accepted: #3699ff;
    --status-completed: #2fc193;
    --status-canceled: #df4f5d;
    --status-edited: #80068b;
}

* {
    margin: 0;
    padding: 0;
    font-family: Poppins, sans-serif;
    box-sizing: border-box;
    outline: none;
}

html {
    font-size: 10px;
    line-height: 1.15;
    overflow-y: scroll;
}

body {
    margin: 0;
    background-color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 1.6rem;
    color: var(--font-base);
}

form {
    width: inherit;
}
