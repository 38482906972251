.content_wrapper {
    width: 490px;
    padding: 0 10px 10px 10px;
}

.label {
    cursor: default;
    display: block;
    font-weight: 500;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 3px;
    -webkit-user-select: none;
    user-select: none;
}
