.content {
    padding: 0 10px 10px 10px;
}

.main_label {
    cursor: default;
    display: block;
    font-weight: 500;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 3px;
}

.calendar_wrapper {
    display: flex;
    border: 1px solid #c0c0c0;
    border-radius: 3px;
    height: 295px;
}

.time_wrapper {
    display: flex;
    flex-direction: column;
}

.time_label {
    height: 80px;
    width: 98px;
    background-color: var(--blue-tint);
    border-bottom: 1px solid #c0c0c0;
    text-align: center;
    padding: 10px;
    user-select: none;
    font-weight: 300;
}

.time_list_wrapper {
    display: flex;
}

.time_list {
    height: 213px;
    width: 50px;
    overflow-y: hidden;
    scrollbar-color: var(--blue-base);
    direction: rtl;
    padding: 2px;

    transition: background-color 0.3s;
}

.time_list:hover {
    overflow-y: scroll;
}

.time_list::-webkit-scrollbar {
    width: 5px;
}

.time_list::-webkit-scrollbar-thumb {
    background: var(--blue-base);
    border-radius: 4px;
}

#minute_list {
    direction: ltr;
    border-left: 1px solid #c0c0c0;
}

.time_hour_li,
.time_minute_li {
    user-select: none;
    height: 30px;
    padding: 7px;
    border-radius: 0.3rem;
    cursor: pointer;
    font-weight: 300;

    transition: background-color 0.3s;
}

.time_hour_li {
    text-align: right;
}

.time_minute_li {
    text-align: left;
}

.time_disabled {
    cursor: not-allowed;
    background-color: white;
    color: var(--gray-base);
    border-radius: 0;
}
.time_disable:hover {
    background-color: white;
}

.time_hour_li:hover,
.time_minute_li:hover {
    background-color: var(--blue-tint);
}

.selected_li {
    background-color: var(--blue-base);
    transition: background-color 0.3s;
    color: #fff;
}

.selected_li:hover {
    background-color: var(--blue-hover);
}
