.content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    min-width: 320px;
    max-width: 1100px;
    height: 100%;
    font-size: 1.6rem;
    color: #303657;
}

.row,
.row_inner,
.row_invalid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
    margin-bottom: 15px;
}

.row_invalid {
    border: 2px solid var(--red-base);
    border-radius: 6px;
}

.h1 {
    width: 100%;
    font-size: 3rem;
    text-align: center;
}

.inputs_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 40px -10px #c9c9c9;
    border-radius: 4px;
    padding: 20px;
    max-width: 100%;
}

.table_wrapper {
    background-color: #fff;
    box-shadow: 0 0 40px -10px #c9c9c9;
    border-radius: 4px;
    padding: 20px;
    max-width: 100%;
}

.inputs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    padding: 15px 0px 0px 0px;
}

.inputs_left {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 490px;
}

.map {
    margin: 0 10px 10px 10px;
    width: 470px;
    height: 320px;
}

.mapPng {
    width: 470px;
    height: 320px;
    object-fit: contain;
}
.label {
    cursor: default;
    display: block;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 3px;
    margin-right: 15px;
    margin-top: 6px;
    user-select: none;
}

.input {
    width: 100%;
    padding: 7px 8px;
    background: none;
    margin: 0;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 200;
}

.input[type="text"] {
    background: #fff;
}

.commission {
    border: 1px solid var(--gray-dark);
    padding: 10px;
}

.commission_invalid {
    border: 2px solid var(--red-base);
    padding: 10px;
}

.button {
    width: 36px;
    min-width: 36px;
    height: 36px;
    min-height: 36px;
    background-color: var(--blue-base);
    margin: 0;
    border-radius: 4px;

    transition: background-color 0.2s;
}

.icon {
    cursor: pointer;
    width: 100%;
    height: 100%;
    color: #fff;
    border-radius: 4px;
}

.th {
    font-weight: 500;
    text-align: left;
    text-overflow: ellipsis;
    padding: 10px 15px;
}

.tr {
    cursor: pointer;
    user-select: none;
    padding: 10px;
    border-bottom: 1px solid var(--gray-base);
}

.tr:hover {
    background-color: var(--blue-tint);
}

.td {
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding: 10px 15px;
    word-wrap: normal;
    word-break: keep-all;
    font-weight: 300;
}

.table {
    max-width: 100%;
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
}

.status {
    width: 6px;
    height: 50px;
}
