.button {
    min-width: 100px;
    width: fit-content;
    cursor: pointer;
    padding: 1rem 0.5rem;
    border: 0;
    border-radius: 4px;
    background-color: var(--blue-base);
    color: white;
    text-decoration: none;
    white-space: nowrap;
    transition: background-color 0.25s;
}

.button:hover {
    background-color: var(--blue-hover);
}

.disabled {
    background-color: var(--gray-base);
    cursor: not-allowed;
}

.success {
    background-color: #00b386;
    transition: background-color 0.3s ease;
}
.success:hover {
    background-color: #009973;
}

.edit {
    background-color: #e5da4e;
    transition: background-color 0.3s ease;
}

.delete {
    background-color: #df4f5d;
    transition: background-color 0.3s ease;
}
.delete:hover {
    background-color: #f13447;
}

.selected {
    background-color: var(--blue-selected);
}

.selected:hover {
    background-color: var(--blue-selected);
}
