.content_wrapper {
    padding: 10px 0;
}

.content {
    background-color: #fff;
    border: 1px solid var(--gray-base);
    border-radius: 4px;
    font-weight: 300;
}

.header {
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid var(--gray-base);
    font-weight: 500;
}

.main {
    padding: 20px 15px;
    display: flex;
    flex-wrap: wrap;
}

.attachment_row {
    display: flex;
    width: 100%;
    justify-content: center;
    color: var(--red-base);
}

.attachment {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    min-height: 80px;
    max-height: 120px;
    width: 20%;
    border: 1px solid #fff;
    padding: 7px;
}

.attachment:hover .attachment_download {
    border-top: 1px solid var(--blue-base);
    border-left: 1px solid var(--blue-base);
    border-bottom: 1px solid var(--blue-base);
    border-right: 1px solid var(--blue-base);
    transition: border 0.2s;
}

.attachment:hover .attachment_delete {
    border-top: 1px solid var(--red-base);
    border-bottom: 1px solid var(--red-base);
    border-right: 1px solid var(--red-base);
    transition: border 0.2s;
}

.attachment:hover .icon {
    color: var(--blue-hover);
}

.attachment_download {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 80%;
    height: 100%;
    padding: 5px 0px;
    border-radius: 8px 0 0 8px;
    border: 1px solid transparent;
    text-align: center;
}

.attachment_download:hover {
    background-color: var(--blue-tint);
    transition: background-color 0.3s;
}

.attachment_delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    cursor: pointer;
    height: 100%;
    align-self: flex-start;
    padding: 2px;
    border-radius: 0 8px 8px 0;
    border: 1px solid transparent;
}

.attachment_delete:hover {
    background-color: var(--red-tint);
    transition: background-color 0.3s;
}

.attachment_drag {
    background-color: var(--blue-tint);
    border: 1px solid var(--blue-base);
    transition: background-color 0.3s;
    transition: border 0.2s;
}

.attachment_add {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    cursor: pointer;
    min-height: 80px;
    max-height: 120px;
    width: 20%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #fff;
}

.attachment_add:hover {
    border-color: var(--green-base);
    background-color: var(--green-tint);
    transition: background-color 0.3s;
    transition: border 0.2s;
}

.attachment_download_add {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100px;
    padding: 5px 0px;
    border-radius: 8px;
    border: 1px solid transparent;
    text-align: center;
}

.attachment_download_add:hover {
    border: 1px solid var(--green-base);
    background-color: var(--green-tint);
    transition: background-color 0.3s;
    transition: border 0.2s;
}

.file_name {
    width: 100%;
    padding: 6px 6px 0 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.icon {
    padding-inline: 10px;
    width: 50px;
    height: 50px;
    color: var(--blue-base);
}

.icon_delete {
    height: 30px;
    color: var(--red-base);
}

.icon_new {
    padding-inline: 5px;
    height: 55px;
    color: var(--green-base);
}

@media screen and (max-width: 700px) {
    .attachment {
        width: 50%;
    }
}
