.spinner {
    display: flex;
    justify-content: center;
    width: 100%;
}

.mainEnter {
    opacity: 0;
}

.mainEnterActive {
    opacity: 1;

    transition: opacity 300ms;
}

.mainExit {
    opacity: 1;
}

.mainExitActive {
    opacity: 0;
}
