.content_wrapper {
    height: fit-content;
    background-color: var(--blue-tint);
    padding: 20px 15px;
    font-weight: 300;
    border-bottom: 1px solid var(--gray-base);
}

.content_options {
    display: flex;
    justify-content: right;
    padding-top: 15px;
}

.content {
    background-color: #fff;
    border: 1px solid var(--gray-base);
    border-radius: 4px;
    margin-bottom: 15px;
}

.header {
    width: 100%;
    padding: 15px 0;
    text-align: center;
    border-bottom: 1px solid var(--gray-base);
    font-weight: 500;
}

.item {
    padding: 20px 15px;
    word-break: break-all;
}

/*----------------- table -------------------*/

.table {
    z-index: 2;
    max-width: 100%;
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
}

.tbody {
    max-height: 350px;
    overflow-y: scroll;
}

.th {
    overflow: hidden;
    font-weight: 500;
    text-align: left;
    text-overflow: ellipsis;
    padding: 10px 15px;
}

.td {
    position: relative;
    z-index: 2;
    border-top: 1px solid var(--gray-base);
    border-bottom: 1px solid var(--gray-base);
    height: 70px;
    text-overflow: ellipsis;
    text-align: left;
    padding: 10px 15px;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    font-weight: 300;
}

.tr_td {
    height: 50px;
}

.tr_td:hover {
    background-color: #f8f8ff;
}

.icon {
    width: 45px;
    height: 45px;
    color: var(--blue-base);
}

.icon:hover {
    color: var(--blue-hover)
}

/* ------------------ tooltip ----------------- */

.td .tooltiptext,
.td .tooltiptext_centered {
    visibility: hidden;
    font-style: normal;
    background-color: var(--nav-base);
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 6px;
    opacity: 0%;

    /* Position the tooltip */
    position: absolute;
    top: 55px;

    transform: translateY(-50%);

    transition:
        opacity 0.4s,
        transform 0.3s;
    z-index: 999;
}

.td:hover .tooltiptext,
.td:hover .tooltiptext_centered {
    visibility: visible;
    opacity: 90%;
    color: #fff;
    transform: translateY(10px);
    padding: 20px;
    z-index: 999;
}

.td:hover .tooltiptext {
    left: 0;
    z-index: 999;
}

.td .tooltiptext_centered {
    left: -20%;
    z-index: 999;
}

.td_with_info {
    cursor: pointer;
    font-style: italic;
    z-index: 999;
}

/* Text area */
.textarea {
    width: 100%;
    max-width: 100%;
    padding: 7px 8px;
    background: none;
    margin: 0;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 200;
    min-height: 90px;
    word-break: break-all;
}

.textarea[type="text"] {
    background: #fff;
}