.wrapper {
    display: flex;
    justify-content: right;
    height: fit-content;
    padding: 5px;
    z-index: 99999;
}

.menu {
    position: relative;
    z-index: 99999;
}

.languages_left,
.languages_center {
    border-radius: 4px;
    border: 1px solid var(--blue-base);
    position: absolute;
    z-index: 4;
    top: 50px;
    background-color: #fff;
}

.languages_left {
    transform: translateX(-60%);
}

.languages_center {
    left: 50%;
    transform: translateX(-50%);
}

.langSelect {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 50px;
    z-index: 99999;
    cursor: pointer;
    border-bottom: 1px solid var(--blue-base);
}

.langSelect:last-child {
    border-bottom: 0;
}

.langSelect:last-child:hover {
    border-radius: 0 0 4px 4px;
}

.langSelect:hover {
    background-color: var(--blue-tint);
    border-radius: 4px 4px 0 0;
}

.icon_wrapper {
    cursor: pointer;
    width: 40px;
}

.icon {
    width: 40px;
    height: 40px;
    color: var(--blue-base);
}

.icon_selected {
    width: 40px;
    height: 40px;
    color: var(--blue-hover);
}

.icon:hover {
    color: var(--blue-hover);
}
