.content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 15px;

    margin-left: auto;
    margin-right: auto;
    padding: 15px;

    min-width: 220px;
    max-width: 95%;
}

.content_row {
    display: flex;
    flex-wrap: wrap;
    min-height: fit-content;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
}

.h1 {
    width: 100%;
    font-size: 3rem;
}

.table_wrapper {
    background-color: #fff;
    box-shadow: 0 0 40px -10px #c9c9c9;
    border-radius: 4px;
    padding: 20px;
    max-width: 100%;
}

.table {
    max-width: 100%;
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
}

.thead_no_shadow,
.thead {
    position: sticky;
    top: -1px;

    background-color: #fff;
}

.thead {
    box-shadow: 0 2px 0 0 var(--gray-base);
    z-index: 999;
}

.th {
    font-weight: 500;
    text-align: left;
    text-overflow: ellipsis;
    padding: 10px 15px;
}

.tr {
    cursor: pointer;
    user-select: none;
    padding: 10px;
    border-bottom: 1px solid var(--gray-base);
}

.tr:hover {
    background-color: var(--blue-tint);
}

.td {
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding: 10px 15px;
    word-wrap: normal;
    word-break: keep-all;
    font-weight: 300;
}

.status {
    width: 6px;
    height: 50px;
}

/* @@@@@@@@@@@@ */

.tr_exp {
    text-align: center;
    cursor: default;
    user-select: none;
    background-color: #f8f8ff;
}

.td_exp {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 20px 15px;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    font-weight: 300;
    border-bottom: 1px solid var(--gray-base);
}

.td_exp_content {
    display: flex;
    flex-flow: column;
    gap: 15px;
}

.td_exp_heading {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

/* @@@@@@@@@ DispatcherView */

.td_exp_offers {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dedede;
    text-align: center;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
}

.td_exp_offers_heading {
    padding: 15px 0;
}

.td_exp_offer {
    cursor: pointer;
    border-top: 1px solid #dedede;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    min-height: 70px;
}

.offer_tr {
    height: 50px;
}

.offer_tr:hover {
    background-color: #f8f8ff;
}

/* @@@@@@@@@@ Admin view */

.mapPng {
    width: 100%;
    height: 320px;
    object-fit: contain;
}

#status {
    padding: 5px 0 5px 10px;
}

/* Responsivenes */
@media screen and (max-width: 700px) {
    .content {
        padding: 5px;
    }

    .content_row {
        justify-content: center;
    }

    .table_wrapper {
        padding: 3px;
        max-width: 700px;
        overflow: hidden;
    }

    .thead {
        border: none;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        margin: 0;
        position: absolute;
        width: 1px;
    }

    .tr {
        border-bottom: 3px solid var(--gray-base);
        display: block;
        padding: 3px;
        margin-bottom: 10px;
    }

    .td {
        border-bottom: 1px solid var(--gray-base);
        padding: 3px;
        height: 50px;
        display: block;
        font-size: 1.4rem;
        text-align: right;
    }

    .status {
        float: right;
        width: 40px;
        height: 20px;
    }

    #status {
        padding: 3px;
    }

    .td::before {
        text-overflow: ellipsis;
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.4rem;
    }

    .td:last-child {
        border-bottom: 0;
    }
}

/* dla CSSTransition */
.enter {
    opacity: 0;
}

.enterActive {
    opacity: 1;
    transition: opacity 300ms;
}

.exit {
    opacity: 1;
}

.exitActive {
    opacity: 0;
    transition: opacity 300ms;
}

.input_wrapper {
    border: 2px solid transparent;
}

.input {
    width: 100%;
    padding: 7px 8px;
    background: none;
    margin: 0;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 200;
}

.input[type="text"] {
    background: #fff;
}

/* Firefox */
input[type="number"] {
    --moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.statuses {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 20px;
}

.status_wrapper {
    display: flex;
    align-items: center;
    gap: 7px;
}

.status_header {
    width: 10px;
    height: 10px;
}

.status_table {
    width: 6px;
    height: 50px;
}

.row_items {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 15px;
}

.button {
    width: 140px;
    cursor: pointer;
    border: 1px solid var(--blue-base);
    background-color: #fff;
    color: var(--blue-base);
    font-size: 1.6rem;
    text-decoration: none;
    white-space: nowrap;
    transition: background-color 0.25s;
}

.button:hover {
    background-color: var(--blue-tint);
}

.button_active {
    cursor: pointer;
    padding: 10px 12px;
    border: 0;
    border-radius: 4px;
    color: white;
    text-decoration: none;
    white-space: nowrap;
    transition: background-color 0.25s;
    background-color: #002c7d;
}

.button_inactive {
    cursor: pointer;
    padding: 10px 12px;
    border: 0;
    border-radius: 4px;
    background-color: #3699ff;
    color: white;
    text-decoration: none;
    white-space: nowrap;
    transition: background-color 0.25s;
}

.button_active:hover,
.button_inactive:hover {
    background-color: var(--blue-hover);
}
