.content {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.header span {
    font-weight: 600;
}

.item {
    display: flex;
    align-items: center;
    gap: 3px;
}

.color {
    width: 10px;
    height: 10px;
}

@media only screen and (max-width: 700px) {
    .content {
        padding: 0 0 15px 0;
    }
}
