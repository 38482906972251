.content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    margin-left: auto;
    margin-right: auto;

    min-width: 320px;
    max-width: 590px;
    height: 100%;
    padding: 15px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    height: 45px;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
    margin-bottom: 15px;
}

.h1 {
    width: 100%;
    font-size: 3rem;
    text-align: center;
}

.inputs_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    background-color: #fff;
    box-shadow: 0 0 40px -10px #c9c9c9;
    border-radius: 4px;
    padding: 30px 10px;
    max-width: 100%;
}
