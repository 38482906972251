.content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    width: 100;
    height: 100%;
    font-size: 1.6rem;
    color: #303657;
    background-color: var(--blue-tint);
}

.row {
    display: flex;
    flex-wrap: wrap;
    height: 45px;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
    margin-bottom: 15px;
}

.h1 {
    width: 100%;
    font-size: 3rem;
    text-align: center;
}

.inputs_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    max-width: 100%;
}

.inputs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    padding: 15px 0px 0px 0px;
}

.inputs_left {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.inputs_right {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 50%;
}

.map {
    margin: 0 10px 10px 10px;
    width: 470px;
    height: 320px;
}

.mapPng {
    width: 470px;
    height: 320px;
    object-fit: contain;
}
