.content {
    display: flex;
    border-radius: 4px;
    justify-content: right;
}

@media screen and (max-width: 700px) {
    .content {
        justify-content: center;
    }
}

.icon_wrapper_disabled {
    width: 36px;
    height: 36px;
    background-color: var(--blue-base);
    padding: 3px;
}

.icon_wrapper {
    width: 36px;
    height: 36px;
    background-color: var(--blue-base);
    padding: 3px;
    cursor: pointer;
}
.icon_wrapper:hover {
    background-color: var(--blue-hover);
}

.icon_wrapper_left {
    border-radius: 4px 0 0 4px;
}

.icon_wrapper_right {
    border-radius: 0 4px 4px 0;
}

.icon {
    width: 30px;
    height: 30px;
    color: #fff;
    cursor: pointer;
}

.page_wrapper {
    width: 36px;
    height: 36px;
    text-align: center;
    padding: 7px 0;
    border-top: 1px solid var(--blue-base);
    border-bottom: 1px solid var(--blue-base);
    cursor: default;
    user-select: none;
}
